export const environment = {
  production: true,
  version: 'v17.0.0',
  qa: true,
  apiBaseUrl: 'https://demo-api.crowdswap.org',
  ETH_APIKEYS_MAINNET: 'https://rpc.ankr.com/eth',
  ETH_APIKEYS_ROPSTEN:
    'https://ropsten.infura.io/v3/793054a908974c48841f0baf6858971a',
  ETH_APIKEYS_RINKEBY:
    'https://rinkeby.infura.io/v3/793054a908974c48841f0baf6858971a',
  ETH_APIKEYS_GOERLI:
    'https://goerli.infura.io/v3/793054a908974c48841f0baf6858971a',
  ETH_APIKEYS_KOVAN:
    'https://kovan.infura.io/v3/793054a908974c48841f0baf6858971a',
  BSCMAIN_PROVIDER_ADDRESS: 'https://bsc-dataseed.binance.org/',
  BSCTEST_PROVIDER_ADDRESS: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  POLYGON_MAINNET_PROVIDER_ADDRESS: 'https://polygon-rpc.com/',
  POLYGON_MUMBAI_PROVIDER_ADDRESS:
    'https://rpc-mumbai.maticvigil.com/v1/ca597f2e23b8592e939722d68dcf82ad569205e9',
  AVALANCHE_PROVIDER_ADDRESS: 'https://rpc.ankr.com/avalanche',
  AVALANCHE_FUJI_PROVIDER_ADDRESS: 'https://api.avax-test.network/ext/bc/C/rpc',
  APEX_PROVIDER_ADDRESS: 'https://rpc.omniaverse.io',
  ARBITRUM_PROVIDER_ADDRESS: 'https://rpc.ankr.com/arbitrum',
  ZKSYNC_PROVIDER_ADDRESS: 'https://mainnet.era.zksync.io',
  ZKSYNCTEST_PROVIDER_ADDRESS: 'https://testnet.era.zksync.dev',
  OPTIMISM_PROVIDER_ADDRESS: 'https://mainnet.optimism.io',
  LINEA_PROVIDER_ADDRESS: 'https://linea-mainnet.public.blastapi.io',
  BASE_PROVIDER_ADDRESS: 'https://mainnet.base.org',
  DEFI_PROVIDER_ADDRESS: 'https://dmc.mydefichain.com/mainnet',
  ROOTSTOCK_PROVIDER_ADDRESS: 'https://public-node.rsk.co',
  ACTIVE_NETWORK: ['56', '137', '42161', '10', '8453'],
  BaseLogoIconsUrl: 'https://asset.crowdswap.org/',
  WalletUrl: 'https://demo-api.crowdswap.org/wallet',
  PriceUrl: 'https://demo-api.crowdswap.org/price',
  NETWORK_SERVICE_BASEURL: 'https://demo-api.crowdswap.org/network',
  PORTFOLIO_BASEURL: 'https://demo-api.crowdswap.org/portfolio',
  AMATES_AI_BASEURL: 'https://ai-demo.amates.ai'
};
