export interface IJob {
  created_at: string;
  cron_job: string;
  id: number;
  name: string;
  paused: boolean;
  room_id: string;
  status: JobStatus;
  nftTokenId: number;
  conversation_id: number;
  cron_jobToDisplay: string;
  botLogo?: string;
}

export enum JobStatus {
  NO_NFT_ASSIGNED = 'no_nft_assigned',
  NOT_STARTED = 'not_started',
  STARTED = 'started'
}

export interface IJobTriggerRespMdl {
  id: number;
  paused: boolean;
  success: boolean;
}

export interface IAssignNFTReqMdl {
  nftTokenId?: number;
}

export interface IAssignNFTRespMdl {
  job_id: string;
  message: string;
  nftTokenId: number;
}

export interface IGenerateJobRespMdl {
  message?: string;
  job_info?: IJob;
  error?: string;
}
