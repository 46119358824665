// Polygon Network

// export const PlanFactoryContractAddress =
//   '0x6bCA44105532f9fB2ecB0446Bd8Ce70E071F61dF';
// export const NftContractAddress = '0x3bFd12Fc2B302607dE965Cb9a8dD6B1d8b4c5F0f';

// Base Network

export const PlanFactoryContractAddress =
  '0xC5118BFB35d2a85a638629a16F039116d88E37e2';
export const NftContractAddress = '0x10c7bDBf0dD8806E09bF117C6E229a267C8d7083';
